
















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class SurveyItem extends Vue {
    @Prop() coupon!: any
    redirectToEditCouponPage (couponId:any) {
      if (this.$store.state.userData.role === 'super-admin') {
        this.$router.push('/sadmin/coupons/' + couponId + '/edit')
      } else if (this.$store.state.userData.role === 'brand') {
        this.$router.push('/brand/coupons/' + couponId + '/edit')
      } else if (this.$store.state.userData.role === 'staff') {
        this.$router.push('/staff/coupons/' + couponId + '/edit')
      }
    }
}
