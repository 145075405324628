var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("div", { staticClass: "grey-logo-circle" }, [
        _c("img", {
          staticClass: "img-in-circle",
          attrs: {
            src:
              _vm.coupon.image + "?w=150" ||
              _vm.$common.getDefaultImage("product")
          },
          on: {
            error: function($event) {
              _vm.coupon.image = _vm.$common.getDefaultImage("product")
            }
          }
        })
      ]),
      _c(
        "span",
        {
          staticClass: "title",
          staticStyle: { "margin-bottom": "30px" },
          attrs: { title: _vm.coupon.name }
        },
        [_vm._v(_vm._s(_vm.coupon.name))]
      ),
      _c("br"),
      _c("div", { staticClass: "text-left float-left" }, [
        _c(
          "button",
          {
            staticClass: "btn-negative",
            attrs: {
              "data-target": "#delete-coupon-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("delete", _vm.coupon)
              }
            }
          },
          [_vm._v("DELETE")]
        )
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn-positive",
            attrs: { "data-toggle": "modal", type: "button" },
            on: {
              click: function($event) {
                return _vm.redirectToEditCouponPage(_vm.coupon.id)
              }
            }
          },
          [_vm._v("EDIT")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }